import { createClient } from "smtpexpress"
require("dotenv").config();

const smtpexpressClient = createClient({
  projectId: `${process.env.GATSBY_SMTPEXPRESS_PROJECT_ID}`,
  projectSecret: `${process.env.GATSBY_SMTPEXPRESS_PROJECT_SECRET}`
});
export default function handleSendEmail ({data, locale}) {
  smtpexpressClient.sendApi.sendMail({
    subject: "A message from the express",
    message: "<h1>New Contact Form</h1>",
    sender: {
      name: "AB Digital Enterprises",
      email: `${process.env.GATSBY_SMTPEXPRESS_SENDER_EMAIL}`
    },
    recipients: {
      name: "My recipient's name",
      email: `${process.env.GATSBY_SMTPEXPRESS_RECIPIENTS_EMAIL}`
    },
    template: {
      id: `${process.env.GATSBY_SMTPEXPRESS_TEMPLATE_ID}`,
      variables: {
        fullname: data.fullname,
        email: data.email,
        phone: data.phone,
        msg: data.msg,
        locale: locale
      }
    }
  }).then((res) => {
    if(res.statusCode === 200) {
      smtpexpressClient.sendApi.sendMail({
        subject: "Thank you for contact AB Digital Enterprises",
        message: "<h1>Thank you for contact AB Digital Enterprises!</h1>",
        sender: {
          name: "AB Digital Enterprises",
          email: `${process.env.GATSBY_SMTPEXPRESS_SENDER_EMAIL}`
        },
        recipients: {
          name: data.fullname,
          email: data.email
        },
        template: {
          id: `${process.env.GATSBY_SMTPEXPRESS_TEMPLATE_ID_CONFIRMATION}`,
          variables: {
            fullname: data.fullname
          }
        }
      })
    }
  })
}